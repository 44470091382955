import React, { useState } from "react";
import styled from "styled-components";
import BackButton from "./BackButton";

const PasswordProtectedRoute = ({ element, password }) => {
    const [inputPassword, setInputPassword] = useState("");
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (inputPassword === password) {
            setIsAuthenticated(true);
        } else {
            alert("Nesprávne heslo");
        }
    };

    if (isAuthenticated) {
        return element;
    }

    return (
        <Container>
            <BackButton to="/" />
            <Heading>Zadajte heslo:</Heading>
            <Form onSubmit={handleSubmit}>
                <PasswordInput
                    type="password"
                    value={inputPassword}
                    onChange={(e) => setInputPassword(e.target.value)}
                    placeholder="Heslo"
                />
                <StyledButton type="submit">Odomknúť</StyledButton>
            </Form>
        </Container>
    );
};

export default PasswordProtectedRoute;

// Štýly pre komponenty
const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f9f9f9;
`;

const Heading = styled.h2`
    margin-bottom: 20px;
    font-size: 1.8em;
    color: #333;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const PasswordInput = styled.input`
    width: 300px;
    padding: 10px;
    font-size: 1em;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    transition: border-color 0.3s;

    &:focus {
        border-color: #007bff;
    }
`;

const StyledButton = styled.button`
    position: relative;
    width: 200px;
    padding: 15px;
    font-size: 1.2em;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    border: none;
    border-radius: 10px;
    background-color: ${(props) =>
        props.light ? "#34A853" : props.purple ? "#A472D8" : "#007bff"};
    color: white;
    transition: background-color 0.3s;

    &:hover {
        background-color: ${(props) =>
            props.light ? "#2b8f44" : props.purple ? "#8c5cbf" : "#0056b3"};
    }
`;
