// src/App.js

import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./Components/Home";
import LastMail from "./Components/LastMail";
import StayTouch from "./Components/StayTouch";
import { getOrCreateUserId } from "./Utils/UUIDGenerator";
import FirstYear from "./YearBook/FirstYear/FirstYear";
import SecondYear from "./YearBook/SecondYear/SecondYear";
import TopicTemplate from "./YearBook/SecondYear/Topic.Template";

import MdToJSON from "./Utils/MdToJSON";
import Texts from "./Texts";
import Feedback from "./Components/Feedback";
import PasswordProtectedRoute from "./Components/PasswordProtectedRoute";

function App() {
    const trackVisit = () => {
        const userId = getOrCreateUserId();
        const url = window.location.href;

        fetch("https://backend.das-vazec.sk/track-visit", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "x-api-key":
                    "ksadlf84u8rwuturohgas8gouiawr8p4oug84ahopor8guasp8ahglsdhgawr8iugpas8guashl",
            },
            body: JSON.stringify({ userId, url }),
        });
    };

    // const [Texts, setTexts] = useState();

    useEffect(() => {
        // MdToJSON()
        //     .then((Texts) => {
        //         console.log("Converted Texts Object:", Texts);
        //         setTexts(Texts);
        //     })
        //     .catch((error) => {
        //         console.error("Error:", error);
        //     });

        const handleClick = () => {
            trackVisit();
        };

        document.addEventListener("click", handleClick);

        return () => {
            document.removeEventListener("click", handleClick);
        };
    }, []);

    if (!Texts) return;

    return (
        <Routes>
            <Route path="/" element={<Home texts={Texts} />} />
            <Route path="/prvy-rocnik" element={<FirstYear />} />
            <Route
                path="/druhy-rocnik"
                element={<SecondYear texts={Texts} />}
            />
            <Route path="/last-mail" element={<LastMail />} />
            <Route path="/stay-touch" element={<StayTouch />} />
            <Route
                path="/feedback"
                element={
                    <PasswordProtectedRoute
                        password="aaa"
                        element={<Feedback />}
                    />
                }
            />

            {Object.entries(Texts).map(([key, value]) => (
                <Route
                    path={"/druhy-rocnik/" + key}
                    element={<TopicTemplate texts={value} />}
                />
            ))}
        </Routes>
    );
}

export default App;
