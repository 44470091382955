import styled from "styled-components";
import BackButton from "./BackButton";

const Container = styled.div`
    padding: 50px 10px 10px 10px;
    height: 100vh; /* Nastaví výšku na celú viditeľnú výšku okna */
    width: 100vw; /* Nastaví šírku na celú viditeľnú šírku okna */
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
`;

const IframeWrapper = styled.div`
    flex-grow: 1; /* Zabezpečí, že iframe vyplní zvyšnú dostupnú plochu */
    display: flex;
`;

const StyledIframe = styled.iframe`
    width: 100%;
    height: 100%;
    border: none;
`;

const Feedback = () => {
    return (
        <Container>
            <BackButton to="/" />
            <IframeWrapper>
                <StyledIframe src="https://docs.google.com/forms/d/e/1FAIpQLScdSWG4zrux9bcmCvSMCOu0ZOpljKAkqsCZP4DQm3I8KSrAoQ/viewform?embedded=true">
                    Načítava sa…
                </StyledIframe>
            </IframeWrapper>
        </Container>
    );
};

export default Feedback;
